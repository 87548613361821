<template>
  <div>
    <PageHeader>
      <template slot="right">
        <div>
          <b-button
            v-if="$allowPermission('agent:manage.blacklist')"
           variant="primary" @click="toggleBlacklistModal(null)">
            <i class="uil-plus mr-1"></i>
            {{ $t('buttons.add') }}
          </b-button>
        </div>
      </template>
    </PageHeader>
    <b-overlay :show="isFetching">
      <b-card>
        <h6>
          {{ `${$t('fields.result')} (${total})` }}
        </h6>
        <b-row align-v="center" class="mb-3">
          <b-col cols="12" md="3">
            <LimitSelect v-model="selectedLimit"/>
          </b-col>
          <b-col cols="12" md="9">
            <b-row>
              <b-col cols="5">
                <b-input-group>
                  <b-form-input
                    v-model="search"
                    :placeholder="`${$t('fields.search')}...`"
                    class="mx-1"
                    type="search"
                  ></b-form-input>
                </b-input-group>
              </b-col>
              <b-col v-if="isOwner">
                <MasterSelectInput 
                  :value="selectedMaster"
                  hide-label
                  @update="onMasterIdChange"
                />
              </b-col>
              <b-col>
                <AgentsSelectInput
                  :master-id="selectedMaster"
                  :value="selectedAgent"
                  hide-label
                  @update="onAgentIdChange"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      <!-- Table -->
      <b-table :fields="fields" :items="filteredList" responsive show-empty>
        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(creator)="data">
          {{ data.value.username || '-' }}
        </template>
        <template #cell(type)="data">
          {{ data.value === 0?
            'ชื่อบัญชี': 'เลขบัญชี'
          }}
        </template>
        <template #cell(value)="data">
          {{ data.value || '-' }}
        </template>
        <template #cell(updator)="data">
          {{ data.value.username || '-' }}
        </template>
        <template #cell(createdAt)="data">
          {{ data.value | datetime }}
        </template>
        <template #cell(id)="data">
          <b-button-group 
          v-if="$allowPermission('agent:manage.blacklist')"
          size="sm">
             <button
              class="btn btn-info btn-sm"
              type="button"
              @click="toggleBlacklistModal(data.value)"
            >
              {{ $t('buttons.edit') }}
            </button>
            <button
              class="btn btn-danger btn-sm"
              type="button"
              @click="onDelete(data.value)"
            >
              {{ $t('buttons.remove') }}
            </button>
          </b-button-group>
        </template>
        <template #empty="">
          <p class="text-center text-muted">{{
            $t('messages.nothing_here')
          }}</p>
        </template>
        <template #cell(note)="data">
          <span v-if="data.value.length === 0">-</span>
          <b-badge v-else>{{ data.value }}</b-badge>
        </template>
      </b-table>
        <!-- pagination -->
      <PaginationInput :per-page="limit" :total="total" @update="(val) => currentPage = val"/>
      </b-card>
    </b-overlay>
    <BlacklistFormModal
      :id="selectedBlacklistId"
      ref="blacklistForm"
      no-btn
    />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  page: {
    title: 'จัดการแบล็คลิสต์',
  },
  components: {
    MasterSelectInput: () =>
      import('@components/master/master-select-input'),
    AgentsSelectInput: () =>
      import('@components/agents/agents-select-input'),
    BlacklistFormModal: () =>
      import('@components/forms/blacklist-form-modal.vue'),
  },
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      selectedBlacklistId: '',
      onSearchTimeout: null,
      fields: [
        '#',
        {
          key: 'creator',
          label: 'สร้างโดย',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'type',
          label: 'ประเภท',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'value',
          label: 'ชื่อ/เลขบัญชี',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'remark',
          label: 'คำอธิบาย',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'updator',
          label: 'แก้ไขโดย',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'createdAt',
          label: this.$t('fields.createdAt'),
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'id',
          label: '',
          thStyle: {
            minWidth: '200px',
          },
          class: 'text-right',
        },
      ],
      isRemarkModalActive: false,
      remarkForm: {
        remark: '',
      },
      search: '',
      selectedMaster: '',
      selectedAgent: '',
      selectedNote: '',

    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.blacklist.isFetchingBlacklists,
      isUpdating: (state) => state.blacklist.isUpdatingBlacklist,
    }),
    ...mapGetters(['isOwner','blacklists']),
    filteredList() {
      return this.blacklists.items || []
    },
    pagination() {
      return this.blacklists.meta
    },
    limit() {
      return this.pagination?.itemsPerPage
    },
    total() {
      return this.pagination?.totalItems
    },
  },
  watch: {
    currentPage(val) {
      if(val){
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    selectedLimit(val) {
      if(val) {
        this.currentPage > 1 ? this.currentPage = 1 : this.fetchData()
      }
    },
    search() {
      this.delaySearch()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchBlacklists', 'deleteBlacklist']),
    fetchData() {
      this.fetchBlacklists({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: this.search,
        agentId: this.selectedAgent,
        masterId: this.selectedMaster,
      })
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.fetchData()
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.fetchData()
    },
    toggleBlacklistModal(id) {
      this.selectedBlacklistId = id
      this.$refs.blacklistForm.toggleModal()
    },
    onDelete(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteBlacklist(id)
          }
        })
    },
  },
}
</script>
